(function ($) {
  // External tools and plugis JS
  gsap.registerPlugin(ScrollTrigger); // Register plugin so you can use defauls
  // gsap.registerPlugin(MotionPathPlugin);

  ScrollTrigger.defaults({ // Defaults are used by all ScrollTriggers
    toggleActions: "restart pause reverse pause", // Scoll effect Forward, Leave, Back, Back Leave
    // markers: true // Easaly remove markers for production
  });

  const d = 0.3;

  window.addEventListener("load", function () {
    //------------------------------------------------------//
    // 👷‍♂️ Header
    //------------------------------------------------------//
    function timelineHeaderLoop(elm) {
      const timeline = gsap.timeline({
        repeat: -1,
        repeatDelay: 0.6,
        yoyo: true,
      });
      timeline
        .to(elm, {
          duration: 4,
          scaleY: 1.03,
          ease: "power2.inOut"
        })
        ;
      return timeline;
    }
    const timelineHeader = gsap.timeline({});


    timelineHeader
      .from(".alloy-header-ornament #back", {
        duration: d * 2,
        autoAlpha: 0,
        scaleY: 0,
        ease: "back.out(1.7)"
      })
      .from(".alloy-header-ornament #front", {
        duration: d * 2,
        autoAlpha: 0,
        scaleY: 0,
        ease: "back.out(1.7)"
      }, "-=0.3")
      .from(".alloy-header-ornament #logo", { duration: d, autoAlpha: 0, yPercent: -50 })
      .add(timelineHeaderLoop(".alloy-header-ornament #back"))
      .add(timelineHeaderLoop(".alloy-header-ornament #front"), "-=0.4")
      ;

    // END 👷‍♂️ Header  -------------------------------------//
    //------------------------------------------------------//
    // 🖼 Photos 
    //------------------------------------------------------//

    document.querySelectorAll(".ACF-photo").forEach(function (container) {

      const item = container.querySelectorAll(".photo");

      const timeline = gsap.timeline({
        scrollTrigger: {
          id: "PHOTO", // Custom label to the marker
          trigger: container, // What element triggers the scroll
          scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
          start: "top center", // Can be top, center, bottom 
          end: "+=100% center", // Can be top, center, bottom 
          // pin: true,
        }
      });

      timeline.from(item, {
        y: 100,
      });

    });
    // END 🖼 Photos -------------------------------------//

    //------------------------------------------------------//
    // 🎩 Fancy content 
    //------------------------------------------------------//
    document.querySelectorAll(".ACF-fancy_content").forEach(function (container) {

      const item = container.querySelectorAll("img");

      const timeline = gsap.timeline({
        scrollTrigger: {
          id: "FANCY", // Custom label to the marker
          trigger: container, // What element triggers the scroll
          scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
          start: "top center", // Can be top, center, bottom 
          end: "+=100% center", // Can be top, center, bottom 
          // pin: true,
        }
      });

      timeline.from(item, {
        y: 100,
      });

    });
    // END 🎩 Fancy content -------------------------------------//




  }, false);

})(jQuery); // Fully reference jQuery after this point.

